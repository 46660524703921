import React from 'react'
import Home from './Home'
import {Route, Routes} from 'react-router-dom';
import Cuisine from './Cuisine';
import MenuItems from './MenuItems';
import Searched from './Searched';
import Cart from './Cart';
import Privacy from './Privacy';


function Pages() {
  return (
    <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/cuisine/:type" element={<Cuisine/>}/>
        <Route path="/restaurant/:type/:table" element={<Cuisine/>}/>
        {/* <Route path="/restaurant/:type/:table?" element={<Cuisine/>}/> */}
        <Route path="/restaurant/:id/:type/:table" element={<MenuItems/>}/>
        <Route path="/searched/:id/:type" element={<Searched/>}/>
        <Route path="/restaurant/:id/cart/:table" element={<Cart/>}/>
    </Routes>
  )
}

export default Pages