import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
// import Fab from '@mui/material/Fab';
// import AddIcon from '@mui/icons-material/Add';
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
// import { MdOutlineMenuBook } from "react-icons/md";
import cartContext from '../context/cart/CartContext';
// import { useHistory } from "react-router-dom";
import { BsCartPlusFill } from "react-icons/bs";
import veglogo from '../images/veg.png';
import nonveglogo from '../images/nonveg.png';



function Searched() {
    // let history = useHistory();
    // const contextTypes = {
    //     router: () => true, // replace with PropTypes.object if you use them
    // }
    const navigate = useNavigate();
    const context = useContext(cartContext);
    const [menuItems, setMenuItems] = useState([]);
    // const [category, setCategory] = useState([]);
    // const check = localStorage.getItem('current_restaurant');
    let params = useParams();
    // const getMenuItems = async (id, type) => {
    //     // const data = await fetch(`https://api.spoonacular.com/recipes/complexSearch?apiKey=${process.env.REACT_APP_API_KEY}&cuisine=${name}`);
    //     const data = await fetch(process.env.REACT_APP_API + 'restaurant/' + id + '/?category=' + type)
    //     // console.log(data)
    //     const dataItems = await data.json();
    //     const categoryList = [];
    //     categoryList.push(dataItems[1]);
    //     setCategory(categoryList);
    //     console.log(id)
    //     setMenuItems(dataItems[2]);
    //     localStorage.setItem(id + "-" + type +"-menuItem", JSON.stringify(dataItems[2]));
    //     localStorage.setItem(id + "-" + type + "-category", JSON.stringify(categoryList))
    // }
    const getMenuItems = async (id, type) => {
        // const data = await fetch(`https://api.spoonacular.com/recipes/complexSearch?apiKey=${process.env.REACT_APP_API_KEY}&cuisine=${name}`);
        const data = await fetch(process.env.REACT_APP_API + 'search/' + id + '/?query=' + type)
        // console.log(data)
        const dataItems = await data.json();
        // const categoryList = [];
        // console.log(id)
        setMenuItems(dataItems);
        // categoryList.push(dataItems[1]);
        // localStorage.setItem(id + "-" + type +"-search", JSON.stringify(dataItems));
        // localStorage.setItem(id + "-" + type + "-category", JSON.stringify(categoryList))
        // setCategory(categoryList);
    }

    useEffect (() => {
        // const check = localStorage.getItem(params.id + "-" + params.type + "-menuItem");
        // const categoryCheck = localStorage.getItem(params.id + "-" + params.type + "-category");
        // if(check) {
        //     setMenuItems(JSON.parse(check));
        //     // setCategory(JSON.parse(categoryCheck));
        // }
        // else {
            getMenuItems(params.id, params.type);
            console.log (params.type);
        // }
    }, [params.id, params.type]);


    function incrementCount(index, price) {
        const current_count = context.cartItemsCount;
        const current_price = context.totalPrice;
        context.setCartItemsCount(current_count + 1);
        context.setTotalPrice(current_price + price);

        const item = menuItems[index];

        // const data = 
        // [ 
        //     ...menuItems.slice(0, index),
        //     Object.assign({}, menuItems[index], { quantity: item.quantity + 1, }),
        //     ...menuItems.slice(index + 1),
        // ]

        // setMenuItems(
        //     data
        // );

        // item = menuItems[index];
        // context.updateCartMap(item, item.quantity + 1)
        const curr_count = context.cartItemsMap.has(item.ItemId) ? context.cartItemsMap.get(item.ItemId) : 0;
        // const curr_count = context.cartItemsMap.get(item);

        context.updateCartMap(item.ItemId, curr_count + 1);
        // new Map(cartItemsMap.set(k,v))
        // context.cartItemsMap.has(item) ? context.setCartItemsMap(new Map(context.cartItemsMap.set(item, curr_count + 1))) : context.setCartItemsMap(new Map(context.cartItemsMap.set(item, 1)));

        if(!context.cartItems.some(listItem => item.ItemId === listItem.ItemId)) {
            context.setCartItems([...context.cartItems, item]);
            console.log(context.cartItems)
        }
    }

    function decrementCount(index, price) {
        const current_count = context.cartItemsCount;
        const current_price = context.totalPrice;
        context.setCartItemsCount(current_count - 1);
        // if(current_count !== 1)
        context.setTotalPrice(current_price - price);

        const item = menuItems[index];

        // const data = 
        // [ 
        //     ...menuItems.slice(0, index),
        //     Object.assign({}, menuItems[index], { quantity: item.quantity - 1, }),
        //     ...menuItems.slice(index + 1),
        // ]

        // setMenuItems(
        //     data
        // );
        
        // item = menuItems[index];
        const curr_count = context.cartItemsMap.get(item.ItemId);
        context.updateCartMap(item.ItemId, curr_count - 1);
        // context.cartItemsMap.has(item) ? context.updateCartMap(item, item.quantity) : context.cartItemsMap.set(item, item.quantity);
        // context.cartItemsMap.has(item) ? context.updateCartMap(item, context.cartItemsMap.get(item) + 1) : context.cartItemsMap.set(item, 1);
    }

  return (
      <>

        {
             <div>
                    {/* <NavLink to={'/restaurant/' + JSON.parse(id) + '/cart'}> */}
                        <BsFillArrowLeftSquareFill size={20} style={menuStyle} onClick={() => navigate('/restaurant/' + context.currentRestaurant + '/' + context.tableNumber)}/>
                    {/* </NavLink> */}
             {/* <div><b>Main Menu</b></div> */}
           </div>
            // <BsFillArrowLeftSquareFill size={70}><>Menu</></BsFillArrowLeftSquareFill>
        }

        {
            menuItems.length === 0? 
            <div style={{padding: "10px", paddingTop: "5px", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: "flex"}}>
                <h2><div size={25} style={{marginTop: "10%"}} onClick={() => navigate(-1)}></div>&nbsp; <h3 style={{marginBottom: "10px", color: "grey"}}>No Search Results.., Search Again OR </h3></h2>  <br></br>
                <div style={{borderRadius: 10, backgroundColor: "black", height: "50px", width: "100px", padding: "15px 15px 50px 15px"}}><div style={{color: "white", justifyContent: 'center', alignItems: 'center', flex: 1, textAlign: 'center', fontWeight: 'bold'}} onClick={() => navigate('/restaurant/' + context.currentRestaurant + '/' + context.tableNumber)}>Explore Items</div></div>
                
            </div> : <h3 style={{marginBottom: "10px", color: "grey"}}>Your Search Results.. </h3>
        }

        {/* {<h3 style={{marginBottom: "10px", color: "grey"}}>Your Search Results.. </h3>} */}
        {/* {<p></p>} */}
        {/* {category.map((item) => {
            return ( */}
                
            {/* )
        })} */}
        {/* {restaurant[0].Name + "Ayush"} */}
        {/* </Grid> */}
        <Grid style={{marginBottom: "80px"}}>
        {menuItems.map((item, index) => {
            // console.log(item);
            return (
                
                // <NavLink to={'/restaurant/' + restaurant[0].RestaurantId + '/?category=' + item.Name}>
                <div style={{padding: "5px"}} key={item.ItemId}>
                    <Card>
                        {/* <img src={item.image} alt="" loading='lazy'/> */}
                        {/* <img src={process.env.REACT_APP_PHOTOPATH + item.ItemImage} alt={item.Name} loading="lazy"/> */}
                        {/* style={{display: "flex", flexDirection:"row", marginTop: "0%",}} */}
                        <div style={{display: "flex", flexDirection:"row", marginTop: "0%", justifyContent: "space-between"}}>
                            {/* {console.log(item.type)} */}
                            <img src={item.type === "1"? veglogo: nonveglogo} style={{height: "30px", width: "30px", marginLeft: "0px"}} loading="lazy" alt=""/>
                            <h3 style={{ fontSize: 20, marginBottom: "4%"}}>₹{item.Price}</h3>
                        </div>
                        <div style={{display: "flex", flexDirection:"row", marginTop: "0%", justifyContent: "space-between"}}>
                            <div>
                                <p style={{ fontSize: 21, marginTop: "5%", marginLeft: "0px", fontWeight: 'bold'}}>{item.Name}</p>
                                <p style={{ fontSize: 15, marginTop: "5%", marginLeft: "0px", fontWeight: 'bold', color: "gray"}}>{item.Description}</p>
                            </div>
                            {

                            (context.cartItemsMap.has(item.ItemId) && context.cartItemsMap.get(item.ItemId) !== 0) ? 
                                <div>
                                    <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between"}}> 
                                        <button style={style}><h2 style={{color: "white"}} onClick={ () => {decrementCount(index, item.Price)}}>-</h2></button>
                                        <button style={style}><h2 style={{color: "white"}}>{context.cartItemsMap.get(item.ItemId)}</h2></button>
                                        <button style={style}><h2 style={{color: "white"}} onClick={ () => {incrementCount(index, item.Price)}}>+</h2></button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between"}}>
                                        <button style={style} onClick={ () => incrementCount(index, item.Price)}> 
                                            <h2 style={{color: "white"}}>Add +</h2>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </Card>
                    {/* {

                        (context.cartItemsMap.has(item.ItemId) && context.cartItemsMap.get(item.ItemId) !== 0) ? 
                            <div>
                                <div> 
                                    <button style={style}><h3 style={{color: "white"}} onClick={ () => {decrementCount(index, item.Price)}}>-</h3></button>
                                    <div style={style}><h3 style={{color: "white"}}>{context.cartItemsMap.get(item.ItemId)}</h3></div>
                                    <button style={style}><h3 style={{color: "white"}} onClick={ () => {incrementCount(index, item.Price)}}>+</h3></button>
                                </div>
                            </div>
                            :
                            <div>
                                <button style={style} onClick={ () => incrementCount(index, item.Price)}> 
                                    <h3 style={{color: "white"}}>+ Add</h3>
                                </button>
                            </div>
                    } */}

                    {/* // item.quantity === 0 ? 
                        //     <div>
                        //         <button style={style} onClick={ () => incrementCount(index, item.Price)}> 
                        //             <h3 style={{color: "white"}}>+ Add</h3>
                        //         </button>
                        //     </div>
                        //     :
                        //     <div>
                        //         <div> 
                        //             <button style={style}><h3 style={{color: "white"}} onClick={ () => {decrementCount(index, item.Price)}}>-</h3></button>
                        //             <div style={style}><h3 style={{color: "white"}}>{item.quantity}</h3></div>
                        //             <button style={style}><h3 style={{color: "white"}} onClick={ () => {incrementCount(index, item.Price)}}>+</h3></button>
                        //         </div>
                        //     </div> */}
                    
                </div>
                    
                // </NavLink>
            )
        })}
        </Grid>
      </>
  )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1rem;
`
const Card = styled.div`
    img {
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        height: 150px;
        width: 100%;
        border-radius : 2rem;
    }
    a {
        text-decoration: none;
    }
    h2 {
        text-align: center;
        padding: 0rem;
    }
`

const style = {
    backgroundColor: "#000000",
    // background: linear-gradient(to right, #f27121, #e94057),
    // borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    borderRadius: 12,
    padding: "15px 15px 35px 15px",
    // position: "fixed",
    //marginLeft: "80%",
    height: "50px",
    width: "100%",
    // marginTop: "10%"
    // display: "flex",
    // justifyContent: "space-between"
};


// const Wrapper = styled.div`
//     margin: auto;
//     padding: 0%;
// `;

const menuStyle = {
    // width: 70,  
    // height: 70,   
    borderRadius: 30,            
    backgroundColor: 'white',                                    
    // position: 'relative',                                          
    // top: "6%",                                                    
    // left: 0, 


    position: 'fixed',
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    left: 17,
    top: 12,
    // backgroundColor: "white",
    // background: linear-gradient(to right, #f27121, #e94057),
    // borderTop: "1px solid #E7E7E7",
    // textAlign: "center",
    // borderRadius: 30,
    // padding: "1px",
    // position: "fixed",
    //marginLeft: "80%",
    // height: "85px",
    // width: "20%",
    // display: "flex",
    // justifyContent: "space-between"
};


export default Searched
