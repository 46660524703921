import styled from "styled-components";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import {useNavigate} from 'react-router-dom';
import { useContext } from 'react';
import cartContext from '../context/cart/CartContext';

function Search() {

    const context = useContext(cartContext);
    const [input, setInput] = useState("");
    const navigate = useNavigate();

    const submitHandler = (e) => {
        e.preventDefault();
        // {console.log(context.currentRestaurant)}
        const check = localStorage.getItem('current_restaurant');
        if(input.length) {
            navigate('/searched/' + JSON.parse(check) + '/' + input)
        }
    }

    return (
        <FormStyle onSubmit={submitHandler}>
            <div style={{display: 'flex'}}>
                <FaSearch></FaSearch>
                <input
                    onChange={(e) => setInput(e.target.value) }
                    type="search"
                    value={input}
                />
                {/* <h1>{input}</h1> */}
            </div>
        </FormStyle>
    );
}

const FormStyle = styled.form`
    margin: 1rem 0rem;

    div {
        width: 100%;
        position: relative;
    }
    input {
        border: none;
        background: linear-gradient(35deg, #494949, #313131);
        font-size: 1.5rem;
        color: white;
        padding: 0.5rem 3rem;
        border: none;
        border-radius: 1rem;
        outline: none;
        /* width: 100%; */
    }
    svg {
        position : absolute;
        top: 50%;
        left: 0%;
        transform: translate(100%, -50%);
        color: white;
    }
`

export default Search