import { useState } from "react";
import CartContext from "./CartContext";


const CartState = (props) => {

    // const state = {
    //     "name" : "Ayush"
    // }

    const [cartItemsMap, setCartItemsMap] = useState(new Map());

    const updateCartMap = (k,v) => {
        setCartItemsMap(new Map(cartItemsMap.set(k,v)));
        // setCartItemsMap(prev => new Map([...prev, [k, v]]))
    }

    // setCartItemsMap(prev => new Map([...prev, [key, value]]))

    const [cartItemsCount, setCartItemsCount] = useState(0);
    const [itemsInfo, updateItemsInfo] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [currentRestaurant, setCurrentRestaurant] = useState(0);
    const [tableNumber, setTableNumber] = useState(999);
    const [phoneNumber, setPhoneNumber] = useState();
    const [userName, setUserName] = useState();
    const [cartItems, setCartItems] = useState([]);
    const [orderId, setOrderId] = useState(960);
    
    return (
        <CartContext.Provider value={{ cartItemsCount, setCartItemsCount, totalPrice, setTotalPrice, cartItemsMap, updateCartMap, setCartItemsMap, currentRestaurant, setCurrentRestaurant, tableNumber, setTableNumber, phoneNumber, setPhoneNumber, userName, setUserName, orderId, setOrderId, cartItems, setCartItems }}>
            {props.children}
        </CartContext.Provider>
    )
}

export default CartState;