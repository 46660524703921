import React, { useEffect, useState, useContext } from 'react'
import {useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
// import { useContext } from 'react';
import cartContext from '../context/cart/CartContext';


// function SetRestaurant(id) {
//     const context = useContext(cartContext);
//     context.setCurrentRestaurant(id);
// }

function Cuisine() {
    const context = useContext(cartContext);
    const [cuisine, setCuisine] = useState([]);
    const [restaurant, setRestaurant] = useState([]);
    let params = useParams();
    const getCuisine = async (name) => {
        // const data = await fetch(`https://api.spoonacular.com/recipes/complexSearch?apiKey=${process.env.REACT_APP_API_KEY}&cuisine=${name}`);
        const data = await fetch(process.env.REACT_APP_API + 'restaurant/' + name)
        // console.log(data)
        const recipes = await data.json();
        const restaurantList = [];
        restaurantList.push(recipes[0]);
        setRestaurant(restaurantList);
        // console.log(recipes[0])
        setCuisine (recipes[1]);
        localStorage.setItem(name, JSON.stringify(recipes[1]));
        localStorage.setItem(name + "-restaurant", JSON.stringify(restaurantList))
    }
    useEffect (() => {
        const check = localStorage.getItem(params.type);
        const restaurantCheck = localStorage.getItem(params.type + "-restaurant");
        localStorage.setItem("current_restaurant", JSON.stringify(params.type));
        if(check) {
            setCuisine(JSON.parse(check));
            setRestaurant(JSON.parse(restaurantCheck));
        }
        else {
            getCuisine(params.type);
            console.log (params.type);
        }
        context.setCurrentRestaurant(params.type);
        context.setTableNumber(params.table);


        // if(context.cartItems.length == 0)
        //     getAllItems(params.type);
        // const current_restaurant = params.type;
        // console.log(current_restaurant);

        // SetRestaurant(current_restaurant);
        // const context = useContext(cartContext);
        // context.setCurrentRestaurant(params.type);
    }, [params.type, params.table, context]);


    // const getAllItems = async (id) => {
    //     const data = await fetch(process.env.REACT_APP_API + 'cart/' + id + '/')
    //     const dataItems = await data.json();
    //     context.setCartItems(dataItems);
    // }
    // useEffect (() => {
    //     getMenuItems(params.id);
    // }, [params.id, params.table]);

  return (
      <>
        {/* <a href="upi://pay?pa=UPIID@oksbi&amp;pn=JOHN BRITAS AK &amp;cu=INR" class="upi-pay1">Pay Now !</a> */}
        {/* <p>{restaurant}</p> */}
        {/* <Grid> */}
        {restaurant.map((item) => {
            return (
                // <Card key={item.RestaurantId}>
                //     {/* <img src={item.image} `alt="" loading='lazy'/> */}
                //     <img src={process.env.REACT_APP_PHOTOPATH + item.RestaurantImage} alt={item.Name} loading="lazy"/>
                
                <div key={item.RestaurantId}>    
                    <h2>{item.Name}</h2>
                    <br></br>
                </div>
                // </Card>
            )
        })}
        {/* {restaurant[0].Name + "Ayush"} */}
        {/* </Grid> */}
        <Grid>
        {cuisine.map((item) => {
            return (
                <NavLink to={'/restaurant/' + restaurant[0].RestaurantId + '/' + item.Name + '/' + context.tableNumber}>
                    <Card key={item.CategoryId}>
                        {/* <img src={item.image} alt="" loading='lazy'/> */}
                        <p><h4>{item.Name}</h4></p>
                        <Gradient/>
                        <img src={process.env.REACT_APP_PHOTOPATH + item.CategoryImage} alt={item.Name}/>
                    </Card>
                </NavLink>
            )
        })}
        </Grid>
      </>
  )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
    grid-gap: 1rem;
`
// const Card = styled.div`
//     img {
//         margin-left: auto;
//         margin-right: auto;
//         object-fit: cover;
//         height: 150px;
//         width: 100%;
//         border-radius : 2rem;
//     }
//     a {
//         text-decoration: none;
//     }
//     h4 {
//         text-align: center;
//         padding: 1rem;
//     }
// `

const Card = styled.div`
    min-height: 10rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    z-index: 1;
    img {
        border-radius: 2rem;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        height: 150px;
        width: 100%;
    }

    p {
        position: absolute;
        z-index: 10;
        white-space: initial;
        left: 50%;
        bottom: 0%;
        transform: translate(-50%, 0%);
        color: white;
        width: 80%;
        text-align: center;
        font-weight: 800;
        font-size: 1rem;
        height: 50%;
        display: flex;
        justify-content : center;
        align-items: center;
    }
`;

const Gradient = styled.div`
    border-radius: 2rem;
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 93%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
`;


export default Cuisine