import React from 'react'
import styled from 'styled-components';
// import '../index.css';
// import { MdShoppingCart } from "react-icons/md";
// import { BsFillCartCheckFill } from "react-icons/bs";
import { BsFillCartPlusFill } from "react-icons/bs";
import { NavLink } from 'react-router-dom';
import { useContext, useState } from 'react';
import cartContext from '../context/cart/CartContext';
import Modal from 'react-modal';
// import { BottomSheet } from 'react-spring-bottom-sheet';
// import 'react-spring-bottom-sheet/dist/style.css';
// import '../../src/bottomSheetmain.css';
import { useEffect } from 'react';
// import $ from 'jquery';
// import useScript from '../../src/useScript';
// import '../../src/main.js';


// function componentDidMount() {
//     const script = document.createElement("script");
//     script.src = "../../src/main.js";
//     script.async = true;
//     script.onload = () => this.scriptLoaded();
  
//     document.body.appendChild(script);
// }

// function scriptLoaded() {
//     window.A.sort();
// }
 

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      height: "300px",
      width: "300px",
      borderRadius: "20px"
    },
    overlay: {zIndex: 1000}
  };
  


function Cartbox() {

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);

    const [inputName, setInputName] = useState("");
    const [inputPhone, setInputPhone] = useState();



    // const [open, setOpen] = useState(false);

    // var displayClassName;
    // var visibilityClassName;

    // useEffect(() => {
    //     displayClassName = context.cartItemsCount ? 'block' : 'none';
    //     visibilityClassName = context.cartItemsCount ? 'visible' : 'hidden';
    // }, [context.cartItemsCount]);

    
    // useScript.js

    // function runJs() {
    //     const script = document.createElement('script');
    //     script.src = '/main.js';   //(This is external js url)
    //     script.async = true;
    //     // script.onload = function() {
    //     //     alert("Script loaded and ready");
    //     //     document.body.appendChild(script);
    //     // };
    //     document.body.appendChild(script);
    // }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#070707';
    }

    function closeModal() {
        setIsOpen(false);
    }

    // function runJs() {
    //     const script = document.createElement('script');
    //     script.src = '/main.js';   //(This is external js url)
    //     // script.async = true;
    //     // script.onload = function() {
    //     //     // alert("Script loaded and ready");
    //     //     document.body.appendChild(script);
    //     // };
    //     // var x = document.getElementById("sheet");
    //     document.getElementById("root").appendChild(script);
    //     // document.root.appendChild(script);
    // }

    const context = useContext(cartContext);
    const id = localStorage.getItem('current_restaurant');
    // const [open, setOpen] = useState(false);
    if( context.cartItemsCount !== 0 ) {
        return (

            <>

            {/* <div>
                <button onClick={() => setOpen(true)}>Open</button>
                <BottomSheet open={open}>
                    <div>My awesome content here</div>
                </BottomSheet>
            </div> */}

            <div>
                <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={customStyles}
                // portalClassName={modal}
                contentLabel="Example Modal"
                >

                    <div style={{padding: "10px 20px"}}>

                        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Please enter your Name</h2> */}
                        {/* <button onClick={closeModal}>close</button> */}
                        {/* <div>I am a modal</div> */}
                        <form>
                            <h2>Your Name</h2><input onChange={(e) => setInputName(e.target.value) } type="text" value={inputName} style={{width: "210px", height: "35px", fontSize: "22px", padding: "15px 10px", borderRadius: "10px"}} required/><br/>
                            <h2 style={{ marginTop: "5%"}}>Phone No.</h2><input onChange={(e) => setInputPhone(e.target.value) } type="number" value={inputPhone}  style={{width: "210px", height: "35px", fontSize: "22px", padding: "15px 10px", borderRadius: "10px"}} required/>
                        </form>
                        


                        <NavLink to={'/restaurant/' + JSON.parse(id) + '/cart' + '/' + context.tableNumber}>

                            {
                                context.setUserName(inputName) || context.setPhoneNumber(inputPhone)
                                
                            }

                            <button style={{marginTop: "10%", width: "200px", height: "50px", backgroundColor: "black", borderRadius: "25px"}} onClick={closeModal}><div style={{color: "white", fontSize: "20px"}}>Proceed to Order</div></button>

                        </NavLink>

                    </div>
                </Modal>
            </div>
            
            
            
            
            {
                 context.userName.length > 0 && context.phoneNumber > 0 ? 
                
                <NavLink to={'/restaurant/' + JSON.parse(id) + '/cart' + '/' + context.tableNumber}>
                    <Wrapper>
                        {/* <h1>{a.name}</h1> */}
                        <div style={style}> 
                            <div style={{display: 'flex', justifyContent: "space-between"}}>
                                <BsFillCartPlusFill size={25} style={{color: "white"}}/>
                                {/* <h4 style={{color: "white"}}>Cartbox</h4>  */}
                                <h3 style={{marginLeft: "8px", color: "white"}}>{context.cartItemsCount}</h3>
                                {/* <h4>{cartItemsCount}</h4> */}
                            </div>
                            <h3 style={{color: "white"}}>Checkout</h3>
                            <h3 style={{color: "white"}}>₹{context.totalPrice}</h3>
                        </div>
                    </Wrapper>
                </NavLink> 
                :
                
                    <Wrapper>
                        {/* <h1>{a.name}</h1> */}
                        <div style={style} onClick={openModal}> 
                            <div style={{display: 'flex', justifyContent: "space-between"}}>
                                <BsFillCartPlusFill size={25} style={{color: "white"}}/>
                                {/* <h4 style={{color: "white"}}>Cartbox</h4>  */}
                                <h3 style={{marginLeft: "8px", color: "white"}}>{context.cartItemsCount}</h3>
                                {/* <h4>{cartItemsCount}</h4> */}
                            </div>
                            <h3 style={{color: "white"}}>Checkout</h3>
                            <h3 style={{color: "white"}}>₹{context.totalPrice}</h3>
                        </div>
                    </Wrapper>
                
            }

            {/* <NavLink to={'/restaurant/' + JSON.parse(id) + '/cart' + '/' + context.tableNumber}> */}
                {/* <button onClick={openModal}> */}
            </>
      )
    }
    // else {
    //     return <></>
    // }
}


// const row = styled.div`
//     h3{
//         display: flex;
//         display:inline
//     }
//     h4{
//         display: flex;
//         display:inline
//     }
// `


// const modal = styled.div`
//   top: '35%';
//   left: '50%';
//   right: 'auto';
//   bottom: 'auto';
//   width: '60%';
//   transform: 'translate(-40%, -10%)';
//   border-radius: 120rem;
// `

const Wrapper = styled.div`
    margin: auto;
    padding: 0%;
`;

const style = {
    backgroundColor: "#000000",
    // background: linear-gradient(to right, #f27121, #e94057),
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    borderRadius: 10,
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    zIndex: 300,
};




export default Cartbox


