import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BsFillArrowLeftSquareFill, BsCartPlusFill } from "react-icons/bs";
// import { TbShoppingCartPlus } from "react-icons/tb";
import cartContext from '../context/cart/CartContext';
import veglogo from '../images/veg.png';
import nonveglogo from '../images/nonveg.png';
import Axios from "axios";


function Cart() {

    

    const navigate = useNavigate();
    const context = useContext(cartContext);
    const [menuItems, setMenuItems] = useState([]);
    // const [emptyCart, setEmptyCart] = useState(true);
    let params = useParams();
    // context.cartItemsMap.forEach((value, key) => value !== 0? setEmptyCart(false) : 1);



    async function checkOrder() {

        alert("check Order button clicked")

        const data = await fetch(process.env.REACT_APP_API + 'getOrder/')
        const order_data = await data.json();

        console.log(order_data)

    }


    async function placeOrder() {

        alert("clicked")

        Axios.post(process.env.REACT_APP_API + 'getOrder/', JSON.stringify({
                'OrderId': null,
                'RestaurantId': context.currentRestaurant,
                'ItemsNumber': context.cartItemsCount,
                'UserName': context.userName,
                'UserPhone': context.phoneNumber,
                'OrderDateTime': null,
                'TableNumber': context.tableNumber,
                'TotalPrice': context.totalPrice,
            }),
            {
                headers: {
                    "Authorization": process.env.REACT_APP_API_KEY,
                    "Content-Type": 'application/json'
                }
            }
        )
        .then(res => {
            console.log(res)
            console.log(res.data)
            context.setOrderId(res.data);
            postOrderItems()
        })
        .catch(error => console.err(error))
    }

    async function postOrderItems() {

        // const items = [];

        // context.cartItems.map((item, index) => {
        //     (context.cartItemsMap.has(item.ItemId) && context.cartItemsMap.get(item.ItemId) > 0) ?
        //         items.push({
        //             'OrderItemId': null,
        //             'OrderId': context.orderId,
        //             'ItemName': item.Name,
        //             'Quantity': context.cartItemsMap.get(item.ItemId),
        //             'Price': item.Price,
        //         })
        //     :
        //     <></>
        // })


        // Axios.post(process.env.REACT_APP_API + 'getOrder/', JSON.stringify({
        //         'OrderId': null,
        //         'RestaurantId': context.currentRestaurant,
        //         'ItemsNumber': context.cartItemsCount,
        //         'UserName': context.userName,
        //         'UserPhone': context.phoneNumber,
        //         'OrderDateTime': null,
        //         'TableNumber': context.tableNumber,
        //         'TotalPrice': context.totalPrice,
        //     }),
        //     {
        //         headers: {
        //             "Authorization": process.env.REACT_APP_API_KEY,
        //             "Content-Type": 'application/json'
        //         }
        //     }
        // )
        // .then(res => {
        //     console.log(res)
        //     console.log(res.data)
        //     context.setOrderId(res.data);
        //     postOrderItems()
        // })
        // .catch(error => console.err(error))




        alert("clicked")

        context.cartItems.map((item, index) => {
            (context.cartItemsMap.has(item.ItemId) && context.cartItemsMap.get(item.ItemId) > 0) ?
                    Axios.post(process.env.REACT_APP_API + 'getOrderItem/', JSON.stringify({
                        'OrderItemId': null,
                        'OrderId': context.orderId,
                        'ItemName': item.Name,
                        'Quantity': context.cartItemsMap.get(item.ItemId),
                        'Price': item.Price,
                    }),
                    {
                        headers: {
                            "Authorization": process.env.REACT_APP_API_KEY,
                            "Content-Type": 'application/json'
                        }
                    }
                )
                .then(res => console.log(res))
                .catch(error => console.err(error))
            :
            <></>
        })
    }

    // const getMenuItems = async (id) => {
    //     const data = await fetch(process.env.REACT_APP_API + 'cart/' + id + '/')
    //     const dataItems = await data.json();
    //     setMenuItems(dataItems);
    // }
    // useEffect (() => {
    //     getMenuItems(params.id);
    // }, [params.id, params.table]);

    function incrementCount(index, price) {
        const current_count = context.cartItemsCount;
        const current_price = context.totalPrice;
        context.setCartItemsCount(current_count + 1);
        context.setTotalPrice(current_price + price);

        const item = context.cartItems[index];
        const curr_count = context.cartItemsMap.has(item.ItemId) ? context.cartItemsMap.get(item.ItemId) : 0;
        context.updateCartMap(item.ItemId, curr_count + 1);

        if(!context.cartItems.some(listItem => item.ItemId === listItem.ItemId)) {
            context.setCartItems([...context.cartItems, item]);
            console.log(context.cartItems)
        }
    }

    function decrementCount(index, price) {
        const current_count = context.cartItemsCount;
        const current_price = context.totalPrice;
        context.setCartItemsCount(current_count - 1);
        context.setTotalPrice(current_price - price);
        const item = context.cartItems[index];
        const curr_count = context.cartItemsMap.get(item.ItemId);
        context.updateCartMap(item.ItemId, curr_count - 1);
    }

  return (
      <>
     
        {
             <div>
                <BsFillArrowLeftSquareFill size={20} style={menuStyle} onClick={() => navigate(-1)}/>
           </div>
        }

        <div style={{marginBottom: "150px"}}>

            <div style={{display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', }}>
                <div>
                    <div style={{marginBottom: "5px"}}>Table No : <b>{context.tableNumber}</b></div>
                    <div style={{marginBottom: "5px"}}>Order No : <b>#{context.orderId}</b></div>
                </div>
                <div>
                    <div style={{marginBottom: "5px"}}>Name : <b>{context.userName}</b></div>
                    <div style={{marginBottom: "5px"}}>Phone No : <b>{context.phoneNumber}</b></div>
                </div>       
            </div>
            
           
            {
                context.cartItemsCount === 0? 
                <div style={{padding: "10px", paddingTop: "5px", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', display: "flex"}}>
                    <h2><BsCartPlusFill size={25} style={{marginTop: "10%"}} onClick={() => navigate(-1)}/>&nbsp; Cart is Empty</h2>  <br></br>
                    <div style={{borderRadius: 10, backgroundColor: "black", height: "50px", width: "100px", padding: "15px 5px 5px 5px"}}><div style={{color: "white", justifyContent: 'center', alignItems: 'center', flex: 1, textAlign: 'center', fontWeight: 'bold'}} onClick={() => navigate('/restaurant/' + context.currentRestaurant + '/' + context.tableNumber)}>Add Items</div></div>
                    
                </div> : <div>------------------------------------------------------------</div>
            }


            

            <Grid style={{marginBottom: "40px"}}>
            {context.cartItems.map((item, index) => {
                return (
                    
                    (context.cartItemsMap.has(item.ItemId) && context.cartItemsMap.get(item.ItemId) > 0) ?
                        <div style={{padding: "2px"}} key={item.ItemId}>
                            <Card>  
                                <div style={{display: "flex", flexDirection:"row", marginTop: "0%", justifyContent: "space-between"}}>
                                    <img src={item.type === "1"? veglogo: nonveglogo} style={{height: "25px", width: "25px", marginLeft: "0px"}} loading="lazy" alt="veg/nonveg"/>
                                    <h3 style={{ fontSize: 20, marginBottom: "4%"}}>₹{item.Price}</h3>
                                </div>
                                <div style={{display: "flex", flexDirection:"row", marginTop: "0%", justifyContent: "space-between"}}>
                                    <div>
                                        <p style={{ fontSize: 21, marginTop: "0%", marginLeft: "0px", fontWeight: 'bold'}}>{item.Name}</p>
                                        <p style={{ fontSize: 15, marginTop: "5%", marginLeft: "0px", fontWeight: 'bold', color: "gray"}}>{item.Description}</p>
                                    </div>
                                    {

                                    (context.cartItemsMap.has(item.ItemId) && context.cartItemsMap.get(item.ItemId) !== 0) ? 
                                        <div>
                                            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between"}}> 
                                                <button style={style}><h2 style={{color: "white"}} onClick={ () => {decrementCount(index, item.Price)}}>-</h2></button>
                                                <button style={style}><h2 style={{color: "white"}}>{context.cartItemsMap.get(item.ItemId)}</h2></button>
                                                <button style={style}><h2 style={{color: "white"}} onClick={ () => {incrementCount(index, item.Price)}}>+</h2></button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between"}}>
                                                <button style={style} onClick={ () => incrementCount(index, item.Price)}> 
                                                    <h2 style={{color: "white"}}>Add +</h2>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Card>
                        </div>
                        :
                        <>
                        </>
                )

            })}
            </Grid>

            {
                context.cartItemsCount !== 0? 

                <div style={{display: "flex", flexDirection: 'column'}}>
                    <h3>Bill Details</h3>
                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "20px", fontWeight: 'bold'}}>
                        <div>Total Price</div>
                        <div>₹ {context.totalPrice}</div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "10px", fontWeight: 'bold'}}>
                        <div>CGST</div>
                        <div>₹ {context.cartItemsCount}</div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "10px", fontWeight: 'bold'}}>
                        <div>SGST</div>
                        <div>₹ {context.cartItemsCount}</div>
                    </div>
                    <div style={{width: "100%"}}>
                        ------------------------------------------------------------
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "10px", fontWeight: 'bold'}}>
                        <div><h2>To Pay</h2></div>
                        <div><h2>₹ {context.totalPrice}</h2></div>
                    </div>

                    <div>

                        <button onClick={ () => checkOrder()}> check Order</button>
                    </div>

                    <div>

                        <button onClick={ () => postOrderItems()}> post Order Items</button>
                    </div>
                    
                    <Wrapper>
                        <button onClick={ () => placeOrder()}><div style={styleOrder}><h2 style={{color: "white"}}>Place Order</h2></div></button>
                    </Wrapper>
                    
                </div>

                :
                <></>
            }

        </div>
      </>
  )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: 1rem;
`
const Card = styled.div`
    img {
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        height: 150px;
        width: 100%;
        border-radius : 2rem;
    }
    a {
        text-decoration: none;
    }
    h2 {
        text-align: center;
        padding: 0rem;
    }
`

const style = {
    backgroundColor: "#000000",
    // background: linear-gradient(to right, #f27121, #e94057),
    // borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    borderRadius: 12,
    padding: "15px 15px 35px 15px",
    // position: "fixed",
    //marginLeft: "80%",
    height: "50px",
    width: "100%",
    // marginTop: "10%"
    // display: "flex",
    // justifyContent: "space-between"
};

const styleOrder = {
    backgroundColor: "#000000",
    // background: linear-gradient(to right, #f27121, #e94057),
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    borderRadius: 10,
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "5px",
    height: "60px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: 3000,
};

const menuStyle = {
    // width: 70,  
    // height: 70,   
    borderRadius: 30,            
    backgroundColor: 'white',                                    
    // position: 'relative',                                          
    // top: "6%",                                                    
    // left: 0, 


    position: 'fixed',
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    left: 17,
    top: 12,
    // backgroundColor: "white",
    // background: linear-gradient(to right, #f27121, #e94057),
    // borderTop: "1px solid #E7E7E7",
    // textAlign: "center",
    // borderRadius: 30,
    // padding: "1px",
    // position: "fixed",
    //marginLeft: "80%",
    // height: "85px",
    // width: "20%",
    // display: "flex",
    // justifyContent: "space-between"
};

const Wrapper = styled.div`
    margin: auto;
    padding: 0%;
`;

export default Cart



// import React from 'react'
// import { useContext } from 'react'
// import { BsFillArrowLeftSquareFill } from 'react-icons/bs';
// import { useNavigate } from 'react-router-dom';
// import cartContext from '../context/cart/CartContext'

// function Cart() {

//     const context = useContext(cartContext);
//     const navigate = useNavigate();

//     const components = [];
//     const ids = [];

//     context.cartItemsMap.forEach((value, key) => components.push(<li key={key}>{JSON.stringify(key)} + {JSON.stringify(value)}</li>));
//     context.cartItemsMap.forEach((value, key) => components.push({key}));

    
//     return (
//         <>

//             {
//                 <div>
//                         {/* <NavLink to={'/restaurant/' + JSON.parse(id) + '/cart'}> */}
//                             <BsFillArrowLeftSquareFill size={20} style={menuStyle} onClick={() => navigate(-1)}/>
//                         {/* </NavLink> */}
//                 {/* <div><b>Main Menu</b></div> */}
//                 </div>
//                 // <BsFillArrowLeftSquareFill size={70}><>Menu</></BsFillArrowLeftSquareFill>
//             }
    
//             <h1>Cart</h1>
//             {/* {context.cartItemsMap
            
//             } */}
//             {/* {
//                context.cartItemsMap.forEach(function(value, key) {
//                    // value.map(passCode =>(
//                         <div key = {key}>
//                             <h1>{value}</h1>
//                         </div>
//                     //))
//                 })
//             } */}

//             <ul>
//                 {components.map(function(name, index){
//                     return <li key={ index }>{name}</li>;
//                   })}
//             </ul>
            
            
//         </>
//     )
// }
// const menuStyle = {
//     // width: 70,  
//     // height: 70,   
//     borderRadius: 30,            
//     backgroundColor: 'white',                                    
//     // position: 'relative',                                          
//     // top: "6%",                                                    
//     // left: 0, 


//     position: 'fixed',
//     width: 38,
//     height: 38,
//     alignItems: 'center',
//     justifyContent: 'center',
//     left: 17,
//     top: 12,
//     // backgroundColor: "white",
//     // background: linear-gradient(to right, #f27121, #e94057),
//     // borderTop: "1px solid #E7E7E7",
//     // textAlign: "center",
//     // borderRadius: 30,
//     // padding: "1px",
//     // position: "fixed",
//     //marginLeft: "80%",
//     // height: "85px",
//     // width: "20%",
//     // display: "flex",
//     // justifyContent: "space-between"
// };

// export default Cart


// const supportedInstruments = [
    //         {
    //           supportedMethods: ['https://tez.google.com/pay'],
    //           data: {
    //             pa: 'merchant-vpa@xxx',
    //             pn: 'Merchant Name',
    //             tr: '1234ABCD',  // your custom transaction reference ID
    //             url: 'http://url/of/the/order/in/your/website',
    //             mc: '1234', // your merchant category code
    //             tn: 'Purchase in Merchant',
    //             gstBrkUp: 'GST:16.90|CGST:08.45|SGST:08.45', // GST value break up
    //             invoiceNo: 'BillRef123', // your invoice number
    //             invoiceDate: '2019-06-11T13:21:50+05:30', // your invoice date and time
    //             gstIn: '29ABCDE1234F2Z5', // your GSTIN
    //           },
    //         }
    //       ];
    
    //       const details = {
    //         total: {
    //           label: 'Total',
    //           amount: {
    //             currency: 'INR',
    //             value: '10.01', // sample amount
    //           },
    //         },
    //         displayItems: [{
    //           label: 'Original Amount',
    //           amount: {
    //             currency: 'INR',
    //             value: '10.01',
    //           },
    //         }],
    //       };
    
    //       let request = null;
    //         try {
    //             request = new PaymentRequest(supportedInstruments, details);
    //         } catch (e) {
    //             console.log('Payment Request Error: ' + e.message);
    //         return;
    //         }
    //         if (!request) {
    //             console.log('Web payments are not supported in this browser.');
    //         return;
    //         }
    
    //         // Global key for canMakepayment cache.
    // const canMakePaymentCache = 'canMakePaymentCache';
    
    // /**
    //  * Check whether can make payment with Google Pay or not. It will check session storage
    //  * cache first and use the cache directly if it exists. Otherwise, it will call
    //  * canMakePayment method from PaymentRequest object and return the result, the
    //  * result will also be stored in the session storage cache for future usage.
    //  *
    //  * @private
    //  * @param {PaymentRequest} request The payment request object.
    //  * @return {Promise} a promise containing the result of whether can make payment.
    //  */
    // function checkCanMakePayment(request) {
    //   // Check canMakePayment cache, use cache result directly if it exists.
    //   if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
    //     return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
    //   }
    
    //   // If canMakePayment() isn't available, default to assume the method is
    //   // supported.
    //   var canMakePaymentPromise = Promise.resolve(true);
    
    //   // Feature detect canMakePayment().
    //   if (request.canMakePayment) {
    //     canMakePaymentPromise = request.canMakePayment();
    //   }
    
    //   return canMakePaymentPromise
    //       .then((result) => {
    //         // Store the result in cache for future usage.
    //         sessionStorage[canMakePaymentCache] = result;
    //         return result;
    //       })
    //       .catch((err) => {
    //         console.log('Error calling canMakePayment: ' + err);
    //       });
    // }
    
    // var canMakePaymentPromise = checkCanMakePayment(request);
    //   canMakePaymentPromise
    //       .then((result) => {
    //         showPaymentUI(request, result);
    //       })
    //       .catch((err) => {
    //         console.log('Error calling checkCanMakePayment: ' + err);
    //       });
    // }
    
    
    // /**
    // * Show the payment request UI.
    // *
    // * @private
    // * @param {PaymentRequest} request The payment request object.
    // * @param {Promise} canMakePayment The promise for whether can make payment.
    // */
    // function showPaymentUI(request, canMakePayment) {
    //     if (!canMakePayment) {
    //       handleNotReadyToPay();
    //       return;
    //     }
       
    //     // Set payment timeout.
    //     let paymentTimeout = window.setTimeout(function() {
    //       window.clearTimeout(paymentTimeout);
    //       request.abort()
    //           .then(function() {
    //             console.log('Payment timed out after 20 minutes.');
    //           })
    //           .catch(function() {
    //             console.log('Unable to abort, user is in the process of paying.');
    //           });
    //     }, 20 * 60 * 1000); /* 20 minutes */
       
    //     request.show()
    //         .then(function(instrument) {
       
    //           window.clearTimeout(paymentTimeout);
    //           processResponse(instrument); // Handle response from browser.
    //         })
    //         .catch(function(err) {
    //           console.log(err);
    //         });
    //    }
    
    //    /**
    // * Process the response from browser.
    // *
    // * @private
    // * @param {PaymentResponse} instrument The payment instrument that was authed.
    // */
    // function processResponse(instrument) {
    //     var instrumentString = instrumentToJsonString(instrument);
    //     console.log(instrumentString);
       
    //     fetch('/buy', {
    //       method: 'POST',
    //       headers: new Headers({'Content-Type': 'application/json'}),
    //       body: instrumentString,
    //     })
    //         .then(function(buyResult) {
    //           if (buyResult.ok) {
    //             return buyResult.json();
    //           }
    //           console.log('Error sending instrument to server.');
    //         })
    //         .then(function(buyResultJson) {
    //           completePayment(instrument, buyResultJson.status, buyResultJson.message);
       
    //         })
    //         .catch(function(err) {
    //           console.log('Unable to process payment. ' + err);
    //         });
    //    }
       
       
    //    /**
    //    * Notify browser that the instrument authorization has completed.
    //    *
    //    * @private
    //    * @param {PaymentResponse} instrument The payment instrument that was authed.
    //    * @param {string} result Whether the auth was successful. Should be either
    //    * 'success' or 'fail'.
    //    * @param {string} msg The message to log in console.
    //    */
    //    function completePayment(instrument, result, msg) {
    //     instrument.complete(result)
    //         .then(function() {
    //           console.log('Payment succeeds.');
    //           console.log(msg);
    //         })
    //         .catch(function(err) {
    //           console.log(err);
    //         });
    //    }

