import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { NavLink } from "react-router-dom";


function Popular() {

    const [popular, setPopular] = useState([]);

    useEffect(() => {
        getPopular();
    },[]);

    const getPopular = async () => {
        const check = localStorage.getItem("popular");

        if(check) {
            setPopular(JSON.parse(check));
        }
        else {
            // const api = await fetch(`https://api.spoonacular.com/recipes/random?apiKey=${process.env.REACT_APP_API_KEY}&number=9`);
            const api = await fetch(process.env.REACT_APP_API + 'restaurant')
            // print(api)
            const data = await api.json();

            setPopular(data);
            // localStorage.setItem("popular", JSON.stringify(data.recipes));
            localStorage.setItem("popular", JSON.stringify(data));
            // print(data)
            console.log(data);
            // setPopular(data.recipes);
        }
    }

    return (
        <Wrapper>
            <h3>Popular Picks</h3>
            <br></br>
            {console.log(popular)}
            {popular.map((recipe) => {
                return (
                    <NavLink to={'/restaurant/' + recipe.RestaurantId}>
                        <Card key={recipe.RestaurantId}>
                            {/* <p>{recipe.title}</p> */}
                            <p>{recipe.Name}</p>
                            <Gradient/>
                            {/* <img src={recipe.image} alt={recipe.title} loading="lazy"/> */}
                            {/* fetch(process.env.REACT_APP_PHOTOPATH + */}
                            <img src={process.env.REACT_APP_PHOTOPATH + recipe.RestaurantImage} alt={recipe.Location} loading="lazy"/>
                        </Card>
                    </NavLink>
                );
            })}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin: auto;
    padding: 0%;
`;

const Card = styled.div`
    min-height: 11rem;
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
    z-index: 1;
    img {
        border-radius: 2rem;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
        height: 150px;
        width: 100%;
    }

    p {
        position: absolute;
        z-index: 10;
        white-space: initial;
        left: 50%;
        bottom: 0%;
        transform: translate(-50%, 0%);
        color: white;
        width: 80%;
        text-align: center;
        font-weight: 800;
        font-size: 1rem;
        height: 50%;
        display: flex;
        justify-content : center;
        align-items: center;
    }
`;

const Gradient = styled.div`
    border-radius: 2rem;
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 85%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
`;



export default Popular