import Pages from "./pages/Pages";
// import Category from "./components/Category";
import Search from "./components/Search";
import {BrowserRouter} from "react-router-dom";
import Cartbox from "./components/Cartbox";
// import { useState } from "react";
import CartState from "./context/cart/CartState";
import { MdOutlineMenuBook } from "react-icons/md";

function App() {
  return (
    <CartState>
      <BrowserRouter className="App">
        {/* <Category/> */}
        <div style={{display: "flex", flexDirection:"row", marginTop: "5%"}}>
            <MdOutlineMenuBook size={25}/>
            <h3 style={{color: "tomato"}}><b>&nbsp;&nbsp;&nbsp;Bharat</b></h3>
            <h3 style={{color: "green"}}><b>Menu</b></h3>
        </div>
        <Search/>
        <Pages/>
        <Cartbox/>
      </BrowserRouter>
    </CartState>
  );
}

export default App;