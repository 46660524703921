import React from'react';
var perf = require('./privacy.htm');

class Privacy extends React.Component {
   render(){
      return (
        
            <iframe title="hi" src={perf} style={{height: "100%", width: "100%", border: "0.1", position: "absolute", transformStyle: "inherit"}}></iframe> 
       
      );
   }
}
export default Privacy;